/* App.css */
.main_bg {
    background-color: var(--main-color);
  }
  
  :root {
    --main-color: #53C2C8; /* Default color */
    --main-text-color: #53C2C8; /* Default text color */
    --main-hover-color: #5299c8;
  }
  
  .main_text {
    color: var(--main-text-color);
  }

  .btn-main {
    background-color: var(--main-color);
  }
  .btn-main:hover {
    background-color: var(--main-hover-color);
  }

  .btn_lm{
    background-color: var(--main-color);
    color: white;
  }
  .btn_lm:hover {
    background-color: white;
    color: var(--main-color);
  }
  
  .text_links{
    color: var(--main-text-color);
  }

  .text_links:hover{
    color: var(--main-hover-color);
  }

  .btn_insta{
    background-color: white;
    color: var(--main-color);
    border-color: var(--main-color);
    border-width: .1rem;
  }
  .btn_insta:hover {
    background-color: var(--main-color);
    color: white;
  }