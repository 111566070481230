.nav-hover {
  position: relative;
  transition: color 0.3s ease;
}

.nav-hover:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: var(--hover-color);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.nav-hover:hover:after {
  visibility: visible;
  transform: scaleX(1);
}

.nav-hover:hover {
  color: var(--hover-color);
}